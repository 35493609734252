import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    to: "",
    from: "",
    text: "",
    base64: "",
    selectedStationery: 1,
    selectedColor: "#ea5532",
    selectedBgColor: "#f6f4e2",
  },
  mutations: {
    setTo(state, payload) {
      state.to = payload;
    },
    setFrom(state, payload) {
      state.from = payload;
    },
    setText(state, payload) {
      state.text = payload;
    },
    setBase64(state, payload) {
      state.base64 = payload;
    },
    setSelectedStationery(state, payload) {
      state.selectedStationery = payload;
    },
    setSelectedColor(state, payload) {
      console.log("Testsetsete ", payload);
      state.selectedColor = payload;
    },
    setSelectedBgColor(state, payload) {
      state.selectedBgColor = payload;
    },
  },
  actions: {
    updateTo({ commit }, to) {
      commit("setTo", to);
    },
    updateFrom({ commit }, from) {
      commit("setFrom", from);
    },
    updateText({ commit }, text) {
      commit("setText", text);
    },
    updateBase64({ commit }, base64) {
      commit("setBase64", base64);
    },
    updateSelectedStationery({ commit }, selectedStationery) {
      commit("setSelectedStationery", selectedStationery);
    },
    updateSelectedColor({ commit }, selectedColor) {
      commit("setSelectedColor", selectedColor);
    },
  },
  getters: {
    to: (state) => state.to,
    from: (state) => state.from,
    text: (state) => state.text,
    base64: (state) => state.base64,
    selectedStationery: (state) => state.selectedStationery,
  },
});
