import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "introduce",
    component: () => import("../views/Introduce.vue"),
  },
  {
    path: "/write/1",
    name: "write",
    component: () => import("../views/Write1.vue"),
  },
  {
    path: "/write/2",
    name: "write",
    component: () => import("../views/Write2.vue"),
  },
  {
    path: "/write/3",
    name: "write",
    component: () => import("../views/Write3.vue"),
  },
  {
    path: "/complete",
    name: "complete",
    component: () => import("../views/Complete.vue"),
  },
  {
    path: "/image",
    name: "image",
    component: () => import("../views/ImageDownload.vue"),
  },
  {
    path: "/select-stationery",
    name: "selectStationery",
    component: () => import("../views/SelectStationery.vue"), // 여기 경로 확인
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
